import React from 'react';

import Icon from './Icon';

export default function NewslettersIcon({ text = 'Newsletters' }: { text: string; }): JSX.Element {
  return (
    <Icon text={text}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="black" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 4C9 2.89543 9.89543 2 11 2H13C14.1046 2 15 2.89543 15 4H9Z" fillOpacity="0.25" />
        <path d="M6 7C6 5.89543 6.89543 5 8 5H16C17.1046 5 18 5.89543 18 7H6Z" fillOpacity="0.5" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 8C4.89543 8 4 8.89543 4 10V18C4 19.1046 4.89543 20 6 20H18C19.1046 20 20 19.1046 20 18V10C20 8.89543 19.1046 8 18 8H6ZM7.57343 10.6808C7.12098 10.364 6.49745 10.4741 6.18073 10.9265C5.86402 11.379 5.97405 12.0025 6.4265 12.3192L11.4265 15.8192C11.7708 16.0603 12.2291 16.0603 12.5734 15.8192L17.5734 12.3192C18.0259 12.0025 18.1359 11.379 17.8192 10.9265C17.5025 10.4741 16.879 10.364 16.4265 10.6808L12 13.7793L7.57343 10.6808Z"
        />
      </svg>
    </Icon>
  );
}
